// import React, { useCallback, useRef, useState } from "react";
// import FHIR from "fhirclient";
// // import "./index.css";
// import PatientDetails from "./components/PatientDetails";

// function App({ patients }) {
//   const abortControllerRef = useRef(null);
//   const [patientData, setPatientData] = useState(null);
//   const [binary, setBinary] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const handleFetchPatient = useCallback((path) => {
//     if (abortControllerRef.current) {
//       abortControllerRef.current.abort();
//     }

//     const abortController = new AbortController();
//     abortControllerRef.current = abortController;

//     setLoading(true);

//     FHIR.oauth2
//       .ready()
//       .then((client) =>
//         client.request(`${path}`, { signal: abortController.signal })
//       )
//       .then((patient) => {
//         setPatientData(patient);
//       })
//       .catch((error) => {
//         if (error.name === "AbortError") {
//           console.log("Previous request was aborted");
//         } else {
//           console.error(error);
//         }
//         sessionStorage.clear();
//         setTimeout(() => {
//           window.location.reload();
//         }, [1000]);
//       })
//       .finally(() => {
//         setLoading(false);
//       });

//     // FHIR.oauth2
//     //   .ready()
//     //   .then((client) => client.request(`Binary/egEA.Q9a4vYzFRhNRy4JmZg3`))
//     //   .then((binary) => {
//     //     setBinary(binary);
//     //   })
//     //   .catch((error) => {
//     //     if (error.name === "AbortError") {
//     //       console.log("Previous request was aborted");
//     //     } else {
//     //       console.error(error);
//     //     }
//     //     sessionStorage.clear();
//     //     setTimeout(() => {
//     //       window.location.reload();
//     //     }, [1000]);
//     //   })
//     //   .finally(() => {
//     //     setLoading(false);
//     //   });
//   }, []);

//   return (
//     <div className="App">
//       <table className="table table-hover">
//         <thead>
//           <tr>
//             <th>ID</th>
//             <th>Name</th>
//           </tr>
//         </thead>
//         <tbody>
//           {patients?.entry?.map((med) => (
//             <tr
//               key={med?.item?.reference}
//               onClick={() => handleFetchPatient(med?.item?.reference)}
//             >
//               <td className="min-w-[60px]">
//                 {med?.item?.reference?.split("/")[1]}
//               </td>
//               <td className="min-w-[60px]">
//                 {med?.item?.display?.replaceAll(",", " ")}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div style={{ marginTop: "20px" }}>
//         {loading ? "loading........." : ""}
//       </div>
//       {!loading && patientData ? <PatientDetails data={patientData} /> : null}
//       {!loading && binary ? (
//         <div dangerouslySetInnerHTML={{ __html: binary }} />
//       ) : null}
//     </div>
//   );
// }

// export default App;

import * as React from "react";
import { getPath } from "fhirclient/lib/lib";

const rxnorm = "http://www.nlm.nih.gov/research/umls/rxnorm";

function getMedicationName(medCodings = []) {
  let out = "Unnamed Medication(TM)";
  const coding = medCodings.find((c) => c.system === rxnorm);
  if (coding && coding.display) {
    out = coding.display;
  }
  return out;
}

function PatientName({ name = [] }) {
  let entry =
    name.find((nameRecord) => nameRecord.use === "official") || name[0];
  if (!entry) {
    return <h1>No Name</h1>;
  }
  return <h1>{entry.given.join(" ") + " " + entry.family}</h1>;
}

function PatientBanner(patient) {
  return (
    <div>
      <PatientName name={patient.name} />
      <span>
        Gender: <b>{patient.gender}</b>,{" "}
      </span>
      <span>
        DOB: <b>{patient.birthDate}</b>
      </span>
    </div>
  );
}

function MedRow({ med }) {
  const name = getMedicationName(
    getPath(med, "medicationCodeableConcept.coding") ||
      getPath(med, "medicationReference.code.coding")
  );
  return (
    <tr>
      <td>
        <b>{name}</b>
      </td>
      <td>{med.status || "-"}</td>
      <td>{med.intent || "-"}</td>
      <td>{getPath(med, "dosageInstruction.0.text") || "-"}</td>
    </tr>
  );
}

function App({ patient, meds }) {
  return (
    <div className="App">
      <PatientBanner {...patient} />
      <hr />
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Medication</th>
            <th>Status</th>
            <th>Intent</th>
            <th>Dosage Instruction</th>
          </tr>
        </thead>
        <tbody>
          {meds.map((med) => (
            <MedRow key={med.id} med={med} />
          ))}
        </tbody>
      </table>
      {/* <pre>{ JSON.stringify(meds, null, 4) }</pre> */}
    </div>
  );
}

export default App;
