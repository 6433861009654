import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { oauth2 as SMART } from "fhirclient";
import { epicCredentials } from "./epicCredentials";
import "./index.css";

// import Appi from "./Appi";

const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<Appi />);
function getQueryParams() {
  const queryString = window.location.href.split("?")[1];
  if (!queryString) {
    return null; // Return null if no query parameters are present
  }

  const params = {};
  const queryArray = queryString.split("&");

  queryArray.forEach((param) => {
    const [key, value] = param.split("=");
    params[decodeURIComponent(key)] = decodeURIComponent(value || "");
  });

  return params;
}

// Usage
const queryParams = getQueryParams();
console.log(queryParams);

SMART.init({
  iss:
    queryParams?.iss ??
    "https://vendorservices.epic.com/interconnect-amcurprd-oauth/api/FHIR/R4",
  redirectUri: epicCredentials.redirect_url,
  // clientId: "e1d5023b-a528-4555-893e-a4dd8fa3849f",
  clientId: epicCredentials.client_id,
  // clientId: "e3073934-68c1-4ca7-9b59-3d8b934187f1",
  scope: "*",
  // scope: "openid%20fhirUser",
  // clientSecret:
  //   "/P0W4ax/4Ush5K9GiIcn8djRYo7hK4UbomulydG+TwQDC/Srkkyvmt0uDFx+6jZUYycIEyJE87JgZJaLZtEAXA==",
})
  .then(async (client) => {
    // return Promise.all([
    //   client.request("List/eH2gs.0DWwl1z0kwTLvR-EnJgq-uQU.QmRFnyMdtLG6U3"),
    // ]);
    return Promise.all([
      client.patient.read(),
      client.request(`/Encounter?patient=${client.patient.id}`, {
        resolveReferences: "medicationReference",
        pageLimit: 0,
        flat: true,
      }),
    ]);
  })
  .then(
    // ([patientList]) => {
    //   root.render(<App patients={patientList} />);
    // }
    ([patient, meds]) => {
      root.render(<App patient={patient} meds={meds ?? []} />);
    },
    (error) => {
      console.error(error);
      root.render(
        <div className="App">
          <br />
          <pre>{error.stack}</pre>
          <pre className="text-wrap break-words w-full">
            {JSON.stringify(window.location)}
          </pre>
          <pre className="text-wrap break-words w-full">
            {JSON.stringify(queryParams)}
          </pre>
        </div>
      );
    }
  );
